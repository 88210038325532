import React from 'react';
import { IRequestContext } from '@msdyn365-commerce/core';
export const LoginToViewPricingComponent = (
    context: IRequestContext,
    loginText: string,
    priceComponent: JSX.Element,
    shouldBlockPricing: boolean,
    className: string = 'login-to-view-pricing'
): JSX.Element => {
    const {
        user: { signInUrl, isAuthenticated }
    } = context;

    if (isAuthenticated) {
        return priceComponent;
    } else if (!isAuthenticated && !shouldBlockPricing) {
        return priceComponent;
    } else {
        return (
            <div className={className}>
                <a href={signInUrl || '#'}>{loginText}</a>
            </div>
        );
    }
};
